// Inventors
import sga_sol from 'assets/partners/inventors/sga_solutions.png';
import hanwha_hotels_resorts from 'assets/partners/inventors/hanwha_hotels&resorts.png';
import maple_investment_partners from 'assets/partners/inventors/maple_investment_partners.png';
import woori_tech_investment from 'assets/partners/inventors/woori_tech_investment.jpg';
import the_square from 'assets/partners/inventors/the_square.png';

// Alliance
import sk_securities from 'assets/partners/inventors/sk_securities.png';
import woori_bank from 'assets/partners/alliance/woori_bank.png';
import woori_asset_trust from 'assets/partners/alliance/woori_asset_trust.png';
import korea_trust from 'assets/partners/alliance/korea_trust.png';
import nh_investment_securities from 'assets/partners/alliance/nh_investment_securities.png';
import nh_bank from 'assets/partners/alliance/nh_bank.jpg';
import hana_bank from 'assets/partners/alliance/hana_bank.png';

import kiwoom_securities from 'assets/partners/alliance/kiwoom_securities.png';
import daehan_realestate_trust from 'assets/partners/alliance/daehan_realestate_trust.png';
import ibk_seucirities from 'assets/partners/alliance/ibk_securities.png';
import fsc from 'assets/partners/alliance/fsc.png';
import kibo from 'assets/partners/alliance/kibo.jpg';
import kodit from 'assets/partners/alliance/KODIT.jpg';
import seoul_fintech_lab from 'assets/partners/alliance/seoul_fintech_lab.png';

// Certificates
import Iso27001 from 'assets/certificates/ISO27001.png';
import Iso27701 from 'assets/certificates/ISO27701.png';
import SandboxLicense from 'assets/certificates/sandbox-license.png';
import VentureEnterprise from 'assets/certificates/venture-enterprise.png';
import InnoBiz from 'assets/certificates/inno-biz.png';

// Awards
import DeepTech from 'assets/awards/deep-tech.png';
import InfoSecurity from 'assets/awards/information-security.png';
import StartupNEST from 'assets/awards/startup-nest.png';
import GlobalCollabProgram from 'assets/awards/global-collaboration-program.png';
import StartupLeapPackage from 'assets/awards/startup-leap-package.png';
import BabyUnicorn from 'assets/awards/baby-unicorn.jpeg';
import TechProtectionLeading from 'assets/awards/tech-protection-leading.jpeg';

export const investors_ci = {
  sga_sol,
  sk_securities,
  hanwha_hotels_resorts,
  maple_investment_partners,
  woori_tech_investment,
  the_square,
};

export const alliance_ci = {
  sk_securities, 
  woori_bank, 
  woori_asset_trust,
  korea_trust,
  nh_investment_securities,
  nh_bank,
  hana_bank,
  kiwoom_securities,
  daehan_realestate_trust,
  ibk_seucirities,
  fsc,
  kibo,
  kodit,
  seoul_fintech_lab,
};

export const certificates = {
  Iso27001, 
  Iso27701, 
  SandboxLicense, 
  VentureEnterprise, 
  InnoBiz,
}

export const awards = {
  DeepTech, 
  InfoSecurity, 
  StartupNEST, 
  GlobalCollabProgram, 
  StartupLeapPackage, 
  BabyUnicorn,
  TechProtectionLeading, 
}